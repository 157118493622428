import React, { useState, useEffect, useRef } from "react";
// import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import "@fullcalendar/daygrid/main.css";
import moment from "moment";

import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import FullPagePopup from "src/components/FormsDialog/FullPagePopup";
import DetailPopUpModel from "src/components/Calender/DetailPopUpModel";

import DayCalendar from "src/pages/Calender/DayCalendar";
import WeekCalendar from "src/pages/Calender/WeekCalendar";

import AddWorkoutPopup from "src/pages/Calender/component/AddWorkoutPopup";
import EditWorkoutPopup from "src/pages/Calender/component/EditWorkoutPopup";
import CustomConfirmation from "../ModalPopover/Confirmation";
import {
  add_program_workout_api,
  delete_program_workout_Api,
  delete_program_workout_details_Api,
  reorder_events_api,
  update_program_workout_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import ConfirmationWithDescription from "../ModalPopover/ConfirmationWithDescription";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { Icon } from "@iconify/react";
import { handleScrollCalled, is_small_medium_screen } from "src/utils/constant";
import { s3baseUrl } from "src/config/config";
const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function GeneralCalendar({
  programWorkoutList,
  setCurrentDate,
  showAddEvent,
  time_zone,
  currentDate,
  setIsLoading,
  isLoading,
  user_type,
  reloadList,
  calendar_title,
  hideArrowsAndButtons,
  programme,
}) {
  const { userInfo } = useContentSetting();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [eventDetailData, setEventDetailData] = useState({});
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [drawerStateUpdate, setDrawerStateUpdate] = useState(false);
  const [openItration, setopenItration] = useState(false);
  const [selectedTool, setSelectedTool] = useState("month");
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [editItrationDrawerState, setEditItrationDrawerState] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedDate, setSelectDate] = useState("");
  const [dataDel, setDataDel] = useState();
  const [dataCopy, setDataCopy] = useState();
  const [pasteDate, setPasteDate] = useState();
  const [effectedMembers, setEffectedMembers] = useState(programme?.members);
  const [editWorkputData, setEditWorkputData] = useState();
  const calendarRef = useRef(null);
  const [openDragConfig, setOpenDragConfig] = useState(false);
  const [DragConfigDate, setDragConfigDate] = useState(false);
  const [DragConfigData, setDragConfigData] = useState(false);
  const [openCopyPasteConfig, setOpenCopyPasteConfig] = useState(false);
  const handleDeleteEvent = (eventToDelete) => {
    // Handle deletion logic here
  };
  const handleCellHover = (info) => {
    setHoveredDate(info.date);
  };
  const handleCellUnhover = (info) => {
    // setHoveredDate(null);
  };

  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  const handleOpenDrawer = (arg) => {
    setSelectDate(arg);
    setDrawerState(true);
  };
  const handlePaste = async (arg) => {
    setPasteDate(arg);

    let isError = false;
    dataCopy?.workout_exercises?.map((item) => {
      if (
        item?.exercise_type == "general" &&
        item?.parameters.includes("Vdot") &&
        !userInfo.vdot_access
      ) {
        isError = true;
        return;
      }
    });
    if (isError) {
      enqueueSnackbar(
        "Oops! Please select a different workout as you do not have access to add Vdot.",
        { variant: "error" }
      );
      setDataCopy("");
      return;
    }
    setOpenCopyPasteConfig(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };
  const handleAgreeDelete = async (data) => {
    setDataDel(data);

    setEffectedMembers(programme?.members);
    setOpenDelete(true);
    // const result = await delete_program_workout_details_Api(data?._id);
    // if (result.code === 200) {
    //
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };
  const handleCopyExercise = async (data) => {
    setDataCopy(data);
  };
  const handleUpdate = () => {
    handleOpenEditDrawer();
  };
  const handleOpenEditIterationDrawer = () => {
    setEditItrationDrawerState(true);
  };
  const handleUpdateIteration = (data) => {
    setEditWorkputData(data);
    handleOpenEditIterationDrawer();
  };
  const closeDragConfig = async (value) => {
    setOpenDragConfig(false);
    getEventListing(programWorkoutList, time_zone);
    // reloadList();
  };
  const closeCopyPasteConfig = async (value) => {
    setOpenCopyPasteConfig(false);
    setDataCopy("");
    getEventListing(programWorkoutList, time_zone);
    // reloadList();
  };
  const handleCopyPaste = async (value) => {
    setOpenCopyPasteConfig(false);
    const formDataObject = {
      title: dataCopy?.workout_title,
      workout_type: "",
      description: dataCopy?.description,
      status: true,
      exercises: dataCopy?.workout_exercises,
      program_id: dataCopy?.program_info?.program_id,
      video_url: dataCopy?.video_url,
      workout_date: moment(pasteDate).format("YYYY-MM-DD"),
      practice_type: dataCopy?.practice_type,
      video_thumbnail: dataCopy?.video_thumbnail,
    };
    const formData = new FormData();

    formData.append("title", formDataObject.title);
    formData.append("workout_type", formDataObject.workout_type);
    formData.append("description", formDataObject.description);
    formData.append(
      "video_url",
      formDataObject.video_url ? formDataObject.video_url : ""
    );
    if (formDataObject.video_thumbnail) {
      formData.append(
        "duplicate_image_path",
        JSON.stringify(formDataObject.video_thumbnail)
      );
    }
    formData.append("status", formDataObject.status);
    formData.append("exercises", JSON.stringify(formDataObject.exercises));
    formData.append("program_id", formDataObject.program_id);
    formData.append("workout_date", formDataObject.workout_date);
    formData.append("practice_type", formDataObject.practice_type);

    const result = await add_program_workout_api(formData);
    if (result.code === 200) {
      setEditWorkputData(result?.workout);
      enqueueSnackbar(result.message, { variant: "success" });
      setDataCopy("");
      const workout = result?.workout;
      let newData = {
        ...workout,
        title: workout.title,
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title,
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        status: workout.status,
        is_deleteAble: workout?.workout_loggers.length > 0 ? false : true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        index: events.length - 1,
        open: true,
        editable: workout?.workout_loggers.length > 0 ? false : true,
      };
      let list = [...events, newData];
      setEvents(list);
      setTimeout(() => {
        handleScrollCalled(result?.workout?._id);
        // Scroll the cell into view
      });
      // reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setDataCopy("");
    }
  };
  const handleDraged = async (value) => {
    setOpenDragConfig(false);
    console.log(DragConfigDate, DragConfigData, "dlkjfkdsjfakslja");
    const formDataObject = {
      title: DragConfigData?.workout_title,
      description: DragConfigData?.description,
      exercises: DragConfigData?.workout_exercises,
      workout_type: "",
      status: true,
      workout_date: moment(DragConfigDate).format("YYYY-MM-DD"),
      video_url: DragConfigData?.video_url,
      video_thumbnail: DragConfigData?.video_thumbnail,
    };
    const formData = new FormData();

    formData.append("title", formDataObject.title);
    formData.append("description", formDataObject.description);
    formData.append("exercises", JSON.stringify(formDataObject.exercises));
    formData.append("workout_type", formDataObject.workout_type);
    formData.append("status", formDataObject.status);
    formData.append("workout_date", formDataObject.workout_date);
    formData.append(
      "video_url",
      formDataObject.video_url ? formDataObject.video_url : ""
    );
    if (formDataObject.video_thumbnail) {
      formData.append(
        "duplicate_image_path",
        JSON.stringify(formDataObject.video_thumbnail)
      );
    }
    console.log(formDataObject, "dslfkdjsaa");
    const result = await update_program_workout_api(
      formData,
      DragConfigData?.program_workout_slug
    );

    if (result.code === 200) {
      setEditWorkputData(DragConfigData);
      enqueueSnackbar(result.message, { variant: "success" });
      const workout = result?.program_workout;
      let newData = {
        ...workout,
        title: workout.title,
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title,
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        status: workout.status,
        is_deleteAble: workout?.workout_loggers.length > 0 ? false : true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        index: events.length - 1,
        open: true,
        editable: workout?.workout_loggers.length > 0 ? false : true,
      };
      let list = [...events];
      let indexToUpdate = list.findIndex((item) => item._id === newData?._id);

      if (indexToUpdate !== -1) {
        list[indexToUpdate] = newData;
      }
      setEvents(list);
      const todayCell = document.querySelector(
        `.calendar-workout-card[id="${result?.program_workout?._id}"]`
      ); // Find the DOM element corresponding to today's date

      todayCell.scrollIntoView({ behavior: "instant", block: "center" });
      // reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getEventListing(programWorkoutList, time_zone);
    }
  };
  const handleDelete = async (value) => {
    setOpenDelete(false);

    const result = await delete_program_workout_Api(
      dataDel?.program_workout_slug
    );
    if (result.code === 200) {
      setEditWorkputData();
      enqueueSnackbar(result.message, { variant: "success" });
      let updatedEvents = events.filter(
        (item) => item.program_workout_slug != dataDel.program_workout_slug
      );
      setEvents(updatedEvents);
      const today = moment(dataDel?.workout_date).format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
      const todayCell = document.querySelector(`.fc-day[data-date="${today}"]`); // Find the DOM element corresponding to today's date
      if (todayCell) {
        todayCell.scrollIntoView({ behavior: "instant", block: "start" }); // Scroll the cell into view
      }
      // reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleReorder = async (newdate, newevents) => {
    const ids = newevents.map((event) => event._id);
    let postData = {
      workoutIds: ids,
      // date: moment(newdate).format("YYYY-MM-DD"),
    };
    const result = await reorder_events_api(postData);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const EventCard = ({ workout, onDelete, onHover }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [data, setData] = useState(workout);
    const timeoutRef = useRef(null);
    useEffect(() => {
      // Cleanup function to clear the timeout on component unmount
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);
    const formatWithLeadingZeros = (value) => {
      return value < 10 ? `0${value}` : `${value}`;
    };
    const getMinutesForInterval = (mainindex, index) => {
      const list = [...workout._def.extendedProps.workout_exercises];
      const interval = list[mainindex].interval_duration;
      if (interval <= 60) {
        return index + 1;
      }
      if (interval % 60 == 0) {
        return `${
          (interval / 60) * index +
          1 +
          " – " +
          ((interval / 60) * index + interval / 60)
        }`;
      }
      let startInterval = interval * index;
      let endtInterval = interval * index + interval;
      let startintervalText =
        interval * index
          ? Math.floor(startInterval / 60) +
            " " +
            ":" +
            formatWithLeadingZeros(startInterval % 60)
          : 0;
      let endIntervalText =
        Math.floor(endtInterval / 60) +
        " " +
        ":" +
        formatWithLeadingZeros(endtInterval % 60);

      return `${startintervalText + " – " + endIntervalText}`;
    };
    const handleMouseEnter = () => {
      clearTimeout(timeoutRef.current); // Clear any existing timeout
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      // Introduce a delay of 100 milliseconds before updating state on hover out
      const delay = 300;
      timeoutRef.current = setTimeout(() => {
        setIsHovered(false);
      }, delay);
    };
    const moveEventUp = (eventId) => {
      setEditWorkputData(eventId);
      // Find the index of the event in the events array
      const eventIndex = events.findIndex(
        (event) => event.index === eventId.index
      );

      // Check if the event is not the first one on its date and if it exists in the events array
      if (eventIndex > 0 && eventIndex !== -1) {
        // Swap the event with the one above it
        const temp = events[eventIndex];

        events[eventIndex] = events[eventIndex - 1];
        events[eventIndex - 1] = temp;
        // Swap the values of order and index
        [events[eventIndex].order, events[eventIndex - 1].order] = [
          events[eventIndex - 1].order,
          events[eventIndex].order,
        ];
        [events[eventIndex].index, events[eventIndex - 1].index] = [
          events[eventIndex - 1].index,
          events[eventIndex].index,
        ];

        const changedDateEvents = events.filter(
          (event) => event.workout_date === eventId.workout_date
        );
        setEvents([...events]);
        handleReorder(eventId.workout_date, changedDateEvents);

        setTimeout(() => {
          handleScrollCalled(eventId._id);
          // Scroll the cell into view
        }, 0);
      }
    };

    const moveEventDown = (eventId) => {
      setEditWorkputData(eventId);
      // Find the index of the event in the events array
      const eventIndex = events.findIndex(
        (event) => event.index === eventId.index
      );

      // Check if the event is not the last one on its date and if it exists in the events array
      if (eventIndex < events.length - 1 && eventIndex !== -1) {
        // Swap the event with the one below it
        const temp = events[eventIndex];
        events[eventIndex] = events[eventIndex + 1];
        events[eventIndex + 1] = temp;

        // Swap the values of order and index
        [events[eventIndex].order, events[eventIndex + 1].order] = [
          events[eventIndex + 1].order,
          events[eventIndex].order,
        ];
        [events[eventIndex].index, events[eventIndex + 1].index] = [
          events[eventIndex + 1].index,
          events[eventIndex].index,
        ];

        // Filter the events of the changed date
        const changedDateEvents = events.filter(
          (event) => event.workout_date === eventId.workout_date
        );

        // Call the function to handle reordering on the server/API with only the events of the changed date
        setEvents([...events]);
        handleReorder(eventId.workout_date, changedDateEvents);

        setTimeout(() => {
          handleScrollCalled(eventId._id);
          // Scroll the cell into view
        }, 0);
      }
    };
    const isFirstEventOnDate = (test) => {
      let date = moment(test.workout_date).format("YYYY-MM-DD");
      const sameDateEvents = events.filter((e) => e.date === date);
      return sameDateEvents[0]._id === test._id;
    };

    const isLastEventOnDate = (test) => {
      let date = moment(test.workout_date).format("YYYY-MM-DD");
      const sameDateEvents = events.filter((e) => e.date === date);
      return sameDateEvents[sameDateEvents.length - 1]._id === test._id;
    };
    // useEffect(() => {
    //   if (
    //     editWorkputData?._id == workout._def.extendedProps?._id &&
    //     calendarRef?.current
    //   ) {
    //     // Get today's date in YYYY-MM-DD format
    //     const todayCell = document.querySelector(
    //       `.calendar-workout-card[id="${editWorkputData?._id}"]`
    //     ); // Find the DOM element corresponding to today's date
    //     if (todayCell) {
    //       todayCell.scrollIntoView({ behavior: "instant", block: "center" }); // Scroll the cell into view
    //     }
    //   }
    // }, [events, editWorkputData]);
    return (
      <div
        className="calendar-workout-card"
        id={workout._def.extendedProps?._id}
        style={{
          border:
            editWorkputData?._id == workout._def.extendedProps?._id
              ? "2px solid gold"
              : "0px solid gold",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {is_small_medium_screen() ? (
          <div className="w-100 h-100" onMouseEnter={handleMouseEnter}>
            {
              <div className="all-icon-calendar-workout-general-small-screen d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      workout._def.extendedProps.is_deleteAble
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}>
                    <DeleteIcon
                      className={`${
                        workout._def.extendedProps.is_deleteAble
                          ? "del-icon-calendar-workout-general"
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        workout._def.extendedProps.is_deleteAble
                          ? () => handleAgreeDelete(workout._def.extendedProps)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}>
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() =>
                          handleCopyExercise(workout._def.extendedProps)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isFirstEventOnDate(
                          workout._def.extendedProps
                        )}>
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventUp(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isLastEventOnDate(
                          workout._def.extendedProps
                        )}>
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventDown(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            }
          </div>
        ) : (
          <div className="w-100 h-100" onMouseEnter={handleMouseEnter}>
            {isHovered && (
              <div className="all-icon-calendar-workout-general d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      workout._def.extendedProps.is_deleteAble
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}>
                    <DeleteIcon
                      className={`${
                        workout._def.extendedProps.is_deleteAble
                          ? "del-icon-calendar-workout-general"
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        workout._def.extendedProps.is_deleteAble
                          ? () => handleAgreeDelete(workout._def.extendedProps)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}>
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() =>
                          handleCopyExercise(workout._def.extendedProps)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isFirstEventOnDate(
                          workout._def.extendedProps
                        )}>
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventUp(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isLastEventOnDate(
                          workout._def.extendedProps
                        )}>
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventDown(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            )}
          </div>
        )}

        <div onMouseEnter={handleMouseEnter}>
          <div
            className="calendar-workout-card-title"
            style={{
              backgroundColor: workout._def.extendedProps.workout_color,
            }}
            onMouseEnter={handleMouseEnter}>
            <div
              className="calendar-workout-card-program"
              onClick={() => handleUpdateIteration(workout._def.extendedProps)}>
              {workout._def.extendedProps?.workout_program?.title}
            </div>
            <div
              className="d-flex justify-content-between"
              onMouseEnter={handleMouseEnter}>
              <div
                className="calendar-workout-card-workout"
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }
                onMouseEnter={handleMouseEnter}>
                {workout.title}
              </div>
              <div
                className="d-flex align-items-center"
                onMouseEnter={handleMouseEnter}>
                <Icon
                  onClick={() =>
                    handleUpdateIteration(workout._def.extendedProps)
                  }
                  icon={
                    workout._def.extendedProps.practice_type == "workout"
                      ? "mdi:gym"
                      : workout._def.extendedProps.practice_type == "food"
                      ? "fluent:food-24-filled"
                      : workout._def.extendedProps.practice_type == "mindset"
                      ? "flowbite:brain-outline"
                      : workout._def.extendedProps.practice_type == "video"
                      ? "lets-icons:video-fill"
                      : ""
                  }
                  className="workout-type-icon-container "
                />
                {!workout._def.extendedProps?.open ? (
                  <KeyboardArrowDownIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = true;
                      setEvents(list);
                    }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = false;
                      setEvents(list);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {workout._def.extendedProps?.open && (
            <div
              className="calendar-workout-card-exercises"
              onMouseEnter={handleMouseEnter}>
              <div
                className="calendar-workout-card-container py-1"
                onMouseEnter={handleMouseEnter}
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }>
                {workout._def.extendedProps.practice_type == "video" ? (
                  <>
                    <div className="inner-div workout-calendar-exercise-card my-1">
                      {workout?._def?.extendedProps?.dumyImage ? (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <img
                            className="image-border"
                            src={workout?._def?.extendedProps?.dumyImage}
                            height="50"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <img
                            className="image-border"
                            src={
                              s3baseUrl +
                              workout?._def?.extendedProps?.video_thumbnail
                                ?.thumbnail_1
                            }
                            height="50"
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {workout._def.extendedProps.workout_exercises.map(
                  (exercise, index) => {
                    if (exercise?.exercise_type == "something_else") {
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}>
                            {exercise?.title}
                          </div>
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "amrap" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">AMRAP</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Movements
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}>
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "emom" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">EMOM</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Minute{" "}
                                    {getMinutesForInterval(
                                      index,
                                      intervalIndex
                                    )}
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}>
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.sub_exercises &&
                      exercise?.sub_exercises.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          {exercise?.sub_exercises?.map(
                            (exerData, exerIndex) => {
                              return (
                                <div
                                  className="inner-div"
                                  style={{
                                    whiteSpace: "break-spaces",
                                    marginBottom: "3px",
                                    // overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                  }}>
                                  {exerData?.exercise_info?.title}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}>
                            {exercise?.exercise_info?.title}
                          </div>
                        </div>
                      );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderEventContent = (eventInfo) => {
    return <EventCard workout={eventInfo.event} onDelete={handleDeleteEvent} />;
  };
  const getEventListing = async (workout, time_zone) => {
    let all_events = [];
    // moment.tz.setDefault(time_zone);
    if (workout.length > 0) {
      all_events = workout.map((workout, i) => {
        console.log(userInfo?._id, "dflkjskdjka", workout?.action_id);
        return {
          ...workout,
          title: workout.title,
          color: workout.program_info?.color,
          workout_color: workout.program_info?.color,
          workout_title: workout.title,
          workout_exercises: workout.exercises,
          workout_program: workout.program_info,
          status: workout.status,
          // is_deleteAble:
          //   userInfo?._id != workout?.action_id
          //     ? false
          //     : workout?.workout_loggers.length > 0
          //     ? false
          //     : true,
          is_deleteAble: workout?.workout_loggers.length > 0 ? false : true,

          date: moment(workout.workout_date).format("YYYY-MM-DD"),
          index: i,
          order: i,
          open: true,
          // editable:
          //   userInfo?._id != workout?.action_id
          //     ? false
          //     : workout?.workout_loggers.length > 0
          //     ? false
          //     : true,
          editable: workout?.workout_loggers.length > 0 ? false : true,

          // start_date: moment(workout.start_date_time).format("YYYY-MM-DD"),
          // end_date: moment(workout.end_date_time).format("YYYY-MM-DD"),
          // start: get_start_date_end_time(workout.start_date_time),
          // end: get_start_date_end_time(workout.end_date_time),
          // start_time: get_start_end_time(workout.start_date_time),
          // end_time: get_start_end_time(workout.end_date_time),
        };
      });
    }
    setEvents(all_events);
  };

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    let dates = get_dates(tool);
    setCurrentDate(dates);
    setSelectedTool(tool);
  };
  const handleCloseEditIterationDrawer = () => {
    setEditItrationDrawerState(false);
  };
  const getCenteredText = () => {
    let text = moment(new Date()).format("MMM YYYY");
    if (selectedTool == "month") {
      // text = moment(currentDate.start_date).add(1, "month").format("MMM YYYY");

      if (calendarRef?.current) {
        const calendarApi = calendarRef?.current.getApi();
        const currentMonth = calendarApi.getDate().getMonth(); // Get the current month (0-based index)
        const currentYear = calendarApi.getDate().getFullYear(); // Get the current year

        text = moment().month(currentMonth).format("MMM") + " " + currentYear;
      }
      if (
        moment(new Date()).isBetween(
          currentDate.start_date,
          currentDate.end_date,
          null,
          "[)"
        )
      ) {
        // console.log(
        //   moment(new Date()).isBetween(
        //     currentDate.start_date,
        //     currentDate.end_date,
        //     null,
        //     "[)"
        //   ),
        //   "dsfjldsjfkasdjfka"
        // );
      }
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
      console.log("Start date of the current month:", startDate, endDate);
    }
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    } else {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    }
    console.log(new_object, "djflksdklfla");
    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    hanldeChangeDates(value);
  };

  const handleClickDate = (workout) => {
    setSelectedTool("day");
    setCurrentDate({
      start_date: workout.dateStr,
      end_date: workout.dateStr,
    });
  };
  const handleEventDrop = (info) => {
    const { event, oldEvent, view } = info;

    // Access the dropped date using the view's activeStart property
    const dropDate = view.calendar.formatDate(event.start, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    // Access the dropped event's data
    const eventData = event.extendedProps;
    // assuming you have extendedProps with data

    let isError = false;
    eventData?.workout_exercises?.map((item) => {
      if (
        item?.exercise_type == "general" &&
        item?.parameters.includes("Vdot") &&
        !userInfo.vdot_access
      ) {
        isError = true;
        return;
      }
    });
    if (isError) {
      enqueueSnackbar(
        "Oops! Please select a different workout as you do not have access to add Vdot.",
        { variant: "error" }
      );
      getEventListing(programWorkoutList, time_zone);
      const todayCell = document.querySelector(
        `.calendar-workout-card[id="${eventData?._id}"]`
      ); // Find the DOM element corresponding to today's date

      todayCell.scrollIntoView({ behavior: "instant", block: "center" });
      return;
    }
    setOpenDragConfig(true);
    setDragConfigDate(event.start);
    setDragConfigData(eventData);
    // console.log("Dropped Date:", dropDate, view.calendar, event.start);
    // console.log("Dropped Event Data:", eventData);
  };
  const renderDayCell = (arg) => {
    // Render custom content for each date cell

    const isHovered =
      hoveredDate && arg.date.getTime() === hoveredDate.getTime();
    //  const { dayNumberText, day } = eventInfo;
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          marginTop: -10,
        }}>
        <span>{arg?.dayNumberText}</span>{" "}
        <span
          className="day-cell-name"
          style={{
            opacity: 0.5,
            fontSize: 10,
          }}>
          {moment(arg?.date).format("ddd")}
        </span>
        {isHovered && (
          <div className="cell-icon-workout d-flex text-end w-100">
            {dataCopy && (
              <Tooltip touch="on" placement="bottom" title={"Paste"}>
                <div className="paste-icon-workout-general me-1">
                  <AssignmentTurnedInIcon
                    onClick={() => handlePaste(arg?.date)}
                    sx={{
                      cursor: "pointer",
                      height: 15,
                      width: 15,
                      mb: "2px",
                      mr: "2px",
                    }}
                  />
                </div>
              </Tooltip>
            )}

            <AddIcon
              className="add-icon-workout-general "
              onClick={() => handleOpenDrawer(arg?.date)}
              sx={{
                cursor: "pointer",
              }}
            />
          </div>
        )}
        {/* <AddIcon className="add-icon-workout" /> */}
      </div>
    );
  };
  const goToToday = () => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();
      const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
      const todayCell = document.querySelector(`.fc-day[data-date="${today}"]`); // Find the DOM element corresponding to today's date
      if (todayCell) {
        todayCell.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll the cell into view
      }
    }
  };
  useEffect(() => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
    }
  }, [calendarRef]);
  useEffect(() => {
    let timeout;
    if (!isLoading && calendarRef?.current) {
      timeout = setTimeout(() => {
        const calendarApi = calendarRef?.current.getApi();
        const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
        const todayCell = document.querySelector(
          `.fc-day[data-date="${today}"]`
        ); // Find the DOM element corresponding to today's date
        if (todayCell) {
          todayCell.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll the cell into view
        }
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [isLoading]);
  useEffect(() => {
    getEventListing(programWorkoutList, time_zone);
  }, [programWorkoutList]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataCopy(""); // Clear the data
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, []);
  const eventOrderFunction = (a, b) => {
    // console.log(a, b, "a and  b");
    return a.order - b.order;
  };
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 text-center text-md-start text-lg-start">
          <h2 className="font-size-on-sm">{calendar_title}</h2>
        </div>

        <div className="col-lg-4 col-md-4 col-4 text-start text-lg-start full-calendar-toolbar mt-0 mt-lg-4">
          {!hideArrowsAndButtons && (
            <div class="btn-group toollbar-group-buttons group-buttons-font-size-on-sm">
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("prev");
                }}>
                <ArrowBackIosIcon />
              </button>
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("next");
                }}>
                <ArrowForwardIosIcon />
              </button>
            </div>
          )}
        </div>
        <div className="col-lg-3 col-md-4 col-4  text-end full-calendar-toolbar mt-0 mt-lg-4">
          <h2 className="font-size-on-sm">{getCenteredText()}</h2>
        </div>
        <div className="col-lg-5 col-md-4 col-4  text-end full-calendar-toolbar mt-0 mt-lg-4">
          <div className="group-buttons-font-size-on-sm">
            <button
              className={`small-contained-outlined-button`}
              onClick={() => {
                goToToday();
              }}>
              Today
            </button>
          </div>
        </div>
        <div
          className="col-md-12 full-calendar full-calendar-new-flow mt-3 order-4"
          onMouseLeave={() => setHoveredDate(null)}>
          {selectedTool == "month" ? (
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "today prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
              eventContent={renderEventContent}
              events={events}
              initialView="dayGridMonth"
              dayCellContent={renderDayCell}
              eventDrop={handleEventDrop}
              editable={true}
              selectable={true}
              selectMirror={true}
              eventOrder={eventOrderFunction}
              // dayMaxEvents={
              //   window.screen.width > 768
              //     ? 3
              //     : window.screen.width > 575
              //     ? 2
              //     : 1
              // }
              weekends={true}
              fixedWeekCount={false}
              moreLinkContent={function (args) {
                return "+" + args.num;
              }}
              // dateClick={function (args) {
              //   handleClickDate(args);
              // }}
              moreLinkClick={function (args) {
                handleClickDate(args);
              }}
              dayCellDidMount={(arg) => {
                const cell = arg.el;

                cell.addEventListener("mouseover", () => {
                  handleCellHover(arg);
                });

                cell.addEventListener("mouseout", () => {
                  handleCellUnhover(arg);
                });
              }}
              // dayCellUnmount={(arg) => {
              //   const cell = arg.el;

              //   cell.removeEventListener("mouseover", () => {
              //     handleCellHover(arg);
              //   });

              //   cell.removeEventListener("mouseout", () => {
              //     handleCellUnhover();
              //   });
              // }}
            />
          ) : selectedTool == "week" ? (
            <WeekCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          ) : (
            <DayCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <DetailPopUpModel
        open={popupState}
        setOpen={setPopupState}
        handleAgreeDelete={handleAgreeDelete}
        handleEdit={handleUpdate}
        handleEditIteration={handleUpdateIteration}
        title={"Event Detail"}
        eventDetailData={eventDetailData}
        componentToPassDown={<></>}
      />
      <ConfirmationWithDescription
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to Delete?"}
        descriotion={`If you take action it will delete from ${effectedMembers} users.`}
        handleAgree={handleDelete}
      />

      <ConfirmationWithDescription
        open={openDragConfig}
        setOpen={closeDragConfig}
        title={"Do you want to take this action?"}
        descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
        handleAgree={handleDraged}
      />
      <ConfirmationWithDescription
        open={openCopyPasteConfig}
        setOpen={closeCopyPasteConfig}
        title={"Do you want to paste it here?"}
        descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
        handleAgree={handleCopyPaste}
      />
      <FullPagePopup
        open={editDrawerState}
        setOpen={setEditDrawerState}
        componentToPassDown={<></>}
      />
      <AddWorkoutPopup
        open={drawerState}
        setOpen={setDrawerState}
        date={selectedDate}
        programme={programme}
        reloadList={reloadList}
        setEditWorkputData={setEditWorkputData}
        setEvents={setEvents}
        events={events}
      />
      <EditWorkoutPopup
        open={editItrationDrawerState}
        setOpen={setEditItrationDrawerState}
        date={selectedDate}
        programme={programme}
        data={editWorkputData}
        reloadList={reloadList}
        setEvents={setEvents}
        events={events}
      />
    </div>
  );
}

export default GeneralCalendar;
