import React, { useState, useEffect } from "react";
// import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";

import "@fullcalendar/daygrid/main.css";
import moment from "moment";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import { programme_workout_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import { memberDetailApi } from "src/DAL/member/member";
import GeneralCalendarClient from "src/components/GeneralComponents/GeneralCalendarClient";
// import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
// import { _get_content_setting_localStorage } from "src/DAL/localStorage/localStorage";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  // console.log(startOfMonth, "asfjlks");
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
function MemberCalendar(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [workouts, setworkouts] = useState([]);
  const [reorderWorkouts, setReorderWorkouts] = useState([]);

  const navigate = useNavigate();
  const { id, CalId } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const [selectedTool, setSelectedTool] = useState("month");
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));

  const getEventListing = async () => {
    if (selectedTool !== "month") {
      setIsLoading(true);
    }
    let postData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
      program: CalId,
    };

    const result = await memberDetailApi(id, postData);
    if (result.code === 200) {
      let all_events = [];
      setDefaultTimeZone("asia/karachi");
      const mergedData = [
        ...result?.workouts,
        ...result?.program_workouts.filter(
          (item) =>
            !result?.workouts.find(
              (existingItem) => existingItem.workout_id === item._id
            )
        ),
      ];
      setworkouts(mergedData);
      setReorderWorkouts(result?.reordered_workouts);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEventListing();
  }, [currentDate]);

  const content_setting = "";
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="col-12">
          <IconButton
            className="back-screen-button remove-margen"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <GeneralCalendarClient
        programWorkoutList={workouts}
        reorderedWorkoutList={reorderWorkouts}
        setCurrentDate={setCurrentDate}
        showAddEvent={true}
        time_zone={defaultTimeZone}
        currentDate={currentDate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        reloadList={getEventListing}
        calendar_title={state?.title + " Calendar"}
        programme={state}
        member_id={id}
        member_vdot={state.member_vdot}
      />
    </>
  );
}

export default MemberCalendar;
